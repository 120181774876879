
import Vue from "vue";
import { isEmpty } from "lodash";
// @ts-ignore
import Filterable from "@/components/Header/Tables/Filterable.vue";
// @ts-ignore
import {
  isRequired,
  isNumber,
  isMin,
  isNotZero,
  // @ts-ignore
} from "@/services/rule-services";
// @ts-ignore
import { totalVisiblePagination } from "@/utils/services-global";
// @ts-ignore
import IconBase from "@/components/Commons/Icons/IconBase.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "CustomUsersTable",
  model: {
    prop: "model_data",
    event: "change",
  },
  props: {
    model_data: { type: Array, default: () => [] },
    headers: { type: Array, default: () => [] },
    option: { type: Object, default: () => ({}) },
    filters: { type: Object, default: () => ({}) },
    current_page: { type: Number },
    next_page_url: { type: String },
    path: { type: String },
    per_page: { type: Number },
    prev_page_url: { type: String },
    to: { type: Number },
    total: { type: Number },
  },
  components: { Filterable, IconBase },

  data: () => ({}),

  created() {},

  async mounted() {},

  computed: {
    ...mapGetters("profile", ["getAbility"]),

    getItems() {
      const page: number = structuredClone(this.currentPage);
      const start = (page - 1) * this.per_page;
      const end = start + this.per_page;

      const newItems: any[] = this.model_data.slice(start, end) || [];
      
      if(page > 1 && newItems.length === 0) {
        this.currentPage--;
      }

      return newItems;
    },

    currentPage: {
      set(val) {
        this.$emit("update-current-page", val);
      },
      get() {
        return this.current_page;
      },
    },
    getLength() {
      return Math.ceil(this.total / this.per_page);
    },
    perPage() {
      return !isNaN(this.per_page) && isEmpty(this.per_page) ? this.per_page : 25;
    },
    getTotalVisible() {
      return totalVisiblePagination;
    },
    getRules() {
      return { isRequired, isNumber, isMin, isNotZero };
    },
    hasItems() {
      return this.getItems?.length > 0;
    },
  },

  methods: {
    async handleDelete(item: any) {
      this.$store.dispatch("advertiser/deleteUser", item.id);
    },
    async handleDeleteAll() {
      this.$store.dispatch("advertiser/clearUsers");
    },
  },

  watch: {},
});
