
//@ts-nocheck
import Vue from "vue";
import { AdvertiserDataUpdate, Category } from "@/interfaces/advertiser";
import Alertize from "@/components/Alertize.vue";
import { Notification } from "@/interfaces/proccess";
import { isRequired, isNumber, isDomain, isMinLength, isMaxLength, isMaxCustomLength } from "@/services/rule-services";
import CardTextField from "@/components/Content/CardTextField.vue";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import CardSwitch from "@/components/Content/CardSwitch.vue";
import CardTextFieldToolTip from "@/components/Content/CardTextFieldToolTip.vue";
import { mapActions, mapGetters } from "vuex";
import { getError } from "@/utils/resolveObjectArray";
import { TypeLoading } from "@/interfaces/loading";
import CardActions from "@/components/Content/CardAction.vue";
import { isEmpty, isNull } from "lodash";
import Loading from "@/components/Commons/Loaders/Loading";
import { Type } from "@/interfaces/list";
import CustomUsersControl from "@/views/Admin/Advertisers/CustomUsersControl/index.vue";
//@ts-check

const ACCOUNT = "rappi_amplify";
const MAKERS_NAMES_ENABLED = ["Otros", "Otro", "Otros Nuevo"];

export default Vue.extend({
  name: "AdvertiserCreate",
  props: {},
  components: {
    Alertize,
    CardTextField,
    CardAutocomplete,
    CardSwitch,
    CardTextFieldToolTip,
    CardActions,
    CustomUsersControl,
  },
  data: () => ({
    //custmList
    //valid: true,
    type_selected: {} as Type,
    //custmList
    users_ids: [],
    title: "Edit",
    valid: false,
    show_trademark: false,
    message: "",
    type: "info",
    show_tooltip_app_bundle: false,
    show_tooltip_domain: false,

    advertiser: {
      id: "",
      name: "",
      category_id: "",
      app_bundle: "",
      domain: "",
      active: true,
      external_id: undefined,
      maker: null,
      country_id: 0,
      hasCampaign: false,
      trademark_id: null,
    },
    countries: [],
    makers: [],
    tradeMarks: [],
    control: {
      disabledCountry: false,
      disabledMakers: false,
      before: false,
    },

    rules: {
      rulesCountry: [],
      rulesMakers: [],
    },
  }),
  created() {
    this.$nextTick(async function () {});
  },
  async mounted() {
    try {
      await this.setLoadingData(TypeLoading.loading);
      await this.dispatchCategories();

      await this.fetchAdvertiserData();

      if (this.isAccountRappi) {
        await this.fetchCountries();
        await this.fetchMakers(true);
        await this.fetchTradeMarks();
      }
      await this.setLoadingData();
      this.show_trademark = this.advertiser.trademark_id !== null;
    } catch (error) {
      await this.setLoadingData();
      this.show_trademark = false;
    } finally {
      this.setLoadingData();
    }
    this.$refs.form.resetValidation();
  },
  computed: {
    ...mapGetters("profile", ["isRolReport", "account"]),
    ...mapGetters("advertiser", ["getAdvertiserUserIds"]),
    ...mapGetters("loading", ["isLoading"]),

    getId() {
      return this.$route.params.id;
    },

    getID(): Number {
      return Number(this.$route.params.id);
    },
    isRestricted() {
      return this.$store.state.profile.account.restricted_advertiser;
    },
    getCategories(): Category[] {
      return this.$store.state.advertiser.categories;
    },
    getCountrys() {
      return this.countries;
    },
    getMarkers() {
      return this.makers;
    },
    getTradeMarks() {
      return this.tradeMarks;
    },
    isAlertize(): Boolean {
      return this.$store.state.proccess.alertize;
    },
    isAccountRappi() {
      return this.account.account_type === ACCOUNT;
    },
    getRules() {
      return {
        isRequired,
        isNumber,
        isDomain,
        isMinLength,
        isMaxLength,
        isMaxCustomLength,
      };
    },
    getErrors() {
      return this.$store.state.proccess.errors;
    },
    hasMaker() {
      return this.advertiser.maker !== null;
    },
    isDisabledName() {
      const isAccountRappi = Boolean(this.isAccountRappi);

      // si es despues de la implementacion
      const isBefore = Boolean(this.isBefore);

      const notIncludes = !MAKERS_NAMES_ENABLED.includes(this.advertiser.maker?.value);

      if (!isAccountRappi) return false;

      return isBefore && notIncludes;
    },
    isDisabledMakers() {
      return this.control.disabledMakers;
    },
    isDisabledCountry() {
      return this.control.disabledCountry;
    },
    isBefore() {
      return Boolean(this.control.before);
    },
  },
  methods: {
    ...mapActions("loading", ["setLoadingData"]),
    ...mapActions("person", ["fetchData"]),
    ...mapActions("advertiser", ["makersList", "tradeMarksList"]),
    ...mapActions("proccess", ["setLoadingField"]),

    async fetchAdvertiserData() {
      const advertiser: AdvertiserDataUpdate = await this.dispatchShowAdvertiser();
      await this.setAdvertiser(advertiser);
    },

    handleCancel() {
      this.$router.push({ name: "CustomListIndex" });
    },

    async loadCustomList(id: number) {
      if (!id) return;
      return await this.$store.dispatch("user/list", this.getID);
    },
    async dispatchEditCustomList(updates: any) {
      return this.$store.dispatch("user_list/update", updates);
    },

    //CustomList

    getError(index: any) {
      return getError(this.getErrors, index);
    },
    async setAdvertiser(advertiser: AdvertiserDataUpdate) {
      this.control.before = advertiser.country_id > 0;
      this.advertiser = {
        id: advertiser.id,
        external_id: advertiser.external_id?.toString(),
        name: advertiser.name,
        category_id: advertiser.category_id,
        domain: advertiser.domain || "https://",
        app_bundle: advertiser.app_bundle,
        active: advertiser.active,
        hasCampaign: advertiser.has_campaigns,
        country_id: this.isAccountRappi ? (advertiser.country_id > 0 ? advertiser?.country?.numeric_code : null) : null,
        trademark_id: advertiser.trademark_id,
        maker: Boolean(advertiser.maker) ? { id: advertiser.maker.id, value: advertiser.maker.name } : null,
      };
      this.control.disabledCountry = this.isAccountRappi && this.advertiser.hasCampaign;
      this.control.disabledMakers = this.control.disabledCountry || !(this.advertiser.country_id > 0);
    },
    async validate() {
      let form = this.$refs.form;
      return await form.validate();
    },
    setNotification(notification: Notification) {
      return this.$store.dispatch("proccess/setNotification", notification, { root: true });
    },
    async handleAction(action: any) {
      switch (action.type) {
        case "cancel":
          this.handleCancel();
          break;
      }
    },
    async handleSubmit() {
      try {
        await this.addRules();
        if (!(await this.validate())) return;
        await this.setLoadingData(TypeLoading.loading);
        const userIds: number[] = this.getAdvertiserUserIds;
        await this.update();
      } catch (error) {
        console.error(`[handleSubmit]`, { error });
      } finally {
        await this.setLoadingData();
      }
    },
    handleCancel() {
      this.setNotification({ title: "", message: "", type: "" });
      this.$router.push({ name: "AdvertisersIndex" });
    },
    toggleTooltipAppBundle() {
      this.show_tooltip_app_bundle = !this.show_tooltip_app_bundle;
    },
    toggleTooltipDomain() {
      this.show_tooltip_domain = !this.show_tooltip_domain;
    },
    toggleStatus(status: boolean) {
      this.advertiser.active = Boolean(status);
    },
    async dispatchCategories() {
      return this.$store.dispatch("advertiser/getCategories", {
        root: true,
      });
    },
    async dispatchShowAdvertiser() {
      return this.$store.dispatch("advertiser/show", this.getID, {
        root: true,
      });
    },
    async fetchMakers(addItem: Boolean = false) {
      if (!this.advertiser.country_id) {
        return;
      }
      this.setLoadingField(true);
      const payload = { country_id: this.advertiser.country_id };
      await this.makersList({ filters: payload })
        .then(async resp => {
          await this.parseDataMakers(resp, addItem);
          this.setLoadingField(false);
        })
        .catch(async err => {
          this.makers = [];
          this.setLoadingField(false);
        });
    },
    async fetchCountries() {
      let params = {
        type: "countries",
      };
      this.setLoadingField(true);
      await this.fetchData(params)
        .then(async resp => {
          await this.parseDataCountry(resp);
          this.setLoadingField(false);
        })
        .catch(async err => {
          this.setLoadingField(false);
        });
    },
    async parseDataMakers(makers: any, addItem: Boolean = false) {
      this.makers = [];
      this.makers = makers.map(maker => {
        return { id: maker.id, value: maker.name };
      });
      if (addItem) {
        this.makers.push(this.advertiser.maker);
      }
    },
    async refreshMakers() {
      this.advertiser.maker = null;
      this.advertiser.name = "";
      await this.fetchMakers();
    },

    async parseDataCountry(countries: any) {
      countries.forEach(c => {
        let { title, items } = c;
        this.countries.push({ header: title });
        items.forEach(i => {
          let { id, value } = i;
          this.countries.push({ group: title, id: id, value: value });
        });
      });
    },
    async fetchTradeMarks() {
      if (!this.hasMaker) return;
      this.setLoadingField(true);
      const payload = {
        coutry_id: this.advertiser.country_id,
        maker_id: this.advertiser.maker?.id,
      };
      await this.tradeMarksList({ filters: payload })
        .then(async resp => {
          this.tradeMarks = await this.parseDataTradeMarks(resp);
          this.setLoadingField(false);
        })
        .catch(async err => {
          this.tradeMarks = [];
          this.setLoadingField(false);
        });
    },

    async parseDataTradeMarks(tradeMarks) {
      return tradeMarks.map(tradeMark => {
        return { id: tradeMark.id, value: tradeMark.name };
      });
    },

    async update() {
      const payload = {
        advertiser: {
          ...this.advertiser,
          maker_id: this.advertiser?.maker?.id,
          maker: undefined,
          users_ids: this.getAdvertiserUserIds,
        },
        id: this.getID,
      };
      return this.$store.dispatch("advertiser/update", payload, {
        root: true,
      });
    },

    async addRules() {
      if (this.isAccountRappi && this.isBefore) {
        this.rules.rulesCountry = this.isDisabledCountry ? [] : [this.getRules.isRequired, this.getRules.isNumber];
        this.rules.rulesMakers = this.isDisabledCountry ? [] : [this.getRules.isRequired];
      }
    },
    async setData(value: string) {
      this.advertiser.name = !isEmpty(value)
        ? MAKERS_NAMES_ENABLED.includes(this.advertiser.maker?.value)
          ? this.advertiser.name
          : value
        : "";
    },
    async setData(value: string) {
      this.advertiser.name = value;
    },
  },
  watch: {
    async "advertiser.maker.value"(val, old) {
      if (this.isLoading) return;
      this.advertiser.name = val;
      this.tradeMarks = [];
      await this.setData(val);
    },

    async "advertiser.country_id"(old, val) {
      if (this.isLoading) return;
      this.advertiser.maker = null;
      if (this.isAccountRappi && this.isBefore) {
        if (val != 0 && old != val) {
          await this.refreshMakers();
        }
        if (isNull(old)) {
          this.makers = [];
          this.advertiser.maker = null;
          this.advertiser.name = "";
        }
        this.control.disabledMakers = this.control.disabledCountry || !(this.advertiser.country_id > 0);
      }
    },
  },
});
