
import Vue from "vue";
//@ts-ignore
import { Paginated, SortingOption } from "@/interfaces/paginated";
//@ts-ignore
import ParamService from "@/services/params-service";
import { debounce, isEmpty } from "lodash";
//@ts-ignore
import CustomUsersTable from "@/views/Admin/Advertisers/CustomUsersControl/CustomUsersTable.vue";
//@ts-ignore
import CardActions from "@/components/Content/CardActions.vue";
//@ts-ignore
import RadioButton from "@/components/Content/RadioButton.vue";
//@ts-ignore
import { TypeLoading } from "@/interfaces/loading";
import { mapActions, mapGetters } from "vuex";
//@ts-ignore
import { getPermisionAdvertisers } from "@/utils/permissionResolve";
//@ts-ignore
import NotPermission from "@/views/Errors/not_permission.vue";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import { PaginatedEntity } from "@/models/Paginated";

const PAGINATE_CONFIG = { page: 1, limit: 10 } as Paginated;

export default Vue.extend({
  name: "UsersControl",
  props: {},
  components: {
    CustomUsersTable,
    CardActions,
    RadioButton,
    NotPermission,
    CardAutocomplete,
  },
  data: () => ({
    page: {},
    selectedUsers: null,
    usersList: [], // Listado de usuarios para las opciones de la busqueda (autocomplete)
    headers: [],
    search: null,
    filters: { email: "" },
    paginated: { page: PAGINATE_CONFIG.page, limit: PAGINATE_CONFIG.limit } as Paginated,
    option: { sort: "id", order: "desc" } as SortingOption,
  }),
  created() {
    this.$nextTick(async function () {});
  },
  async mounted() {},
  computed: {
    ...mapGetters("profile", ["getAbility"]),
    ...mapGetters("loading", ["isLoading"]),
    ...mapGetters("advertiser", ["getAdvertiserUser"]),

    /**
     * Listado de usuarios para el componente de busqueda: autocomplete
     */
    getUsersForAutocomplete() {
      const usersList: any[] = this.usersList;
      const paginated: PaginatedEntity = this.getPaginatedResult;
      return usersList.filter(user => !paginated.data.some(s => s.id === user.id));
    },

    /**
     * Datos paginados del listado de usuarios asignados al advertiser.
     */
    getPaginatedResult(): PaginatedEntity {
      return this.getAdvertiserUser;
    },

    /**
     * Listado de los usuarios asignados al advertiser
     */
    getAdvertiserUsersFromStore(): any[] {
      return this.$store.state.advertiser.advertiser?.users || [];
    },

    hasSelectedUsers() {
      return this.selectedUsers?.length > 0;
    },

    prepareTableHeaders() {
      if (isEmpty(this.getPaginatedResult.data)) return [];

      const headers = [
        {
          text: "users.fields.id",
          align: "left",
          value: "id",
          sortable: false,
          filterable: false,
          width: 100,
        },
        {
          text: "email",
          align: "left",
          value: "email",
          sortable: false,
          filterable: false,
          width: 300,
        },
        {
          text: "users.fields.name",
          align: "left",
          value: "name",
          sortable: false,
          filterable: false,
        },
        {
          text: "users.fields.last_name",
          align: "left",
          value: "last_name",
          sortable: false,
          filterable: false,
        },
        {
          text: "users.fields.role",
          align: "left",
          value: "role_name",
          sortable: false,
          filterable: false,
        },
        {
          text: "",
          align: "left",
          sortable: false,
          value: "actions",
          width: "5%",
        },
      ];

      const cols = ["id", "email", "name", "last_name", "role_name", "actions"];
      return headers.filter(item => cols.indexOf(item.value) != -1);
    },

    canIndex() {
      return this.getAbility.can(this.getPermission.actions.index, this.getPermission.subject);
    },

    canCreate() {
      return this.getAbility.can(this.getPermission.actions.create, this.getPermission.subject);
    },

    canEdit() {
      return this.getAbility.can(this.getPermission.actions.update, this.getPermission.subject);
    },

    getPermission() {
      return getPermisionAdvertisers();
    },
  },
  methods: {
    ...mapActions("loading", ["setLoadingData"]),
    ...mapActions("advertiser", ["addNewUsersToAdvertiser", "setPaginatedUsersToAdvertiser"]),

    async getPaginated() {
      const params = await ParamService.getParams(this.paginated, this.filters, this.option);
      return await this.$store.dispatch("user/paginated", params, {
        root: true,
      });
    },
    async onSearchChange(email: string) {
      this.filters.email = email;
      if (!email || this.isLoading) return;
      try {
        this.setLoadingData(TypeLoading.loading);
        const response = await this.getPaginated();
        const newUsers = response.data.map(user => {
          return {
            ...user,
            email_user_name: `${user.email} (${user.name} ${user.last_name})`,
          };
        });
        this.usersList = newUsers;
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        this.setLoadingData();
      }
    },

    handleAddToSelectedItems() {
      this.addNewUsersToAdvertiser(this.selectedUsers);
      this.selectedUsers = null;
    },

    syncData(term: string) {
      this.search = term;
    },

    preventSubmit(event?: KeyboardEvent): void {
      if (event?.key !== "Enter") return;
      // Evita que se envíe el formulario si el campo tiene el foco y se presiona Enter
      event.preventDefault();

      if (!this.selectedUsers) return;

      // Si hay usuarios seleccionados agrega a la tabla
      this.handleAddToSelectedItems();
    },

    async handleUpdateCurrentPage(page: number = 1) {
      this.getPaginatedResult.current_page = page;
    },
  },
  watch: {
    search: debounce(async function (val: string) {
      this.onSearchChange(val);
    }, 700),

    getAdvertiserUsersFromStore: {
      handler(val: any[] = []) {
        const resultPaginate: PaginatedEntity = new PaginatedEntity(this.getPaginatedResult);
        resultPaginate.current_page = 1;
        resultPaginate.data = val;
        resultPaginate.total = val.length;
        this.setPaginatedUsersToAdvertiser(resultPaginate);
      },
      immediate: false,
    },
  },
});
